import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import queue from '@/api/queue/queue'

// ดึง้อมูลรายการคอร์สชุด
const getCourseSetList = async (body) => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'courseset/list',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}


// ดึงข้อมูลคอร์สชุด
const getCourseSetById = async (id) => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'courseset',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}


// เพิ่มข้อมูลคอร์สชุด
const courseSetAdd = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'courseset',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// แก้ไขข้อมูลคอร์สชุด
const courseSetUpdate = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'courseset',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// อัพเดทสถานะรายการคอร์สชุด
const courseSetUpdateStatus = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'courseset/status',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}


//รายการการ คอร์ส ในชุด
const getCourseInSetList = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'courseset/courselist',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//เพิ่มข้อมูลการตรวจในชุด
const courseInSetAdd = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'courseset/course',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//แก้ไขข้อมูลการตรวจในชุด
const courseInSetUpdate = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'courseset/course',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//ลบข้อมูลการตรวจในชุด
const courseInSetRemove = async (id) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'courseset/course',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//ลบข้อมูลการตรวจในชุด
const courseInSetUpdateStatus = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'courseset/coursestatus',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}


export default {
  getCourseSetList,
  getCourseSetById,
  courseSetAdd,
  courseSetUpdate,
  courseSetUpdateStatus,
  getCourseInSetList,
  courseInSetAdd,
  courseInSetUpdate,
  courseInSetRemove,
  courseInSetUpdateStatus,
}
